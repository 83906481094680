// all api list 
export const API_HOME = "/home";
export const API_CHECK_2FA = "/check2fa";
export const API_LOGIN = "/login";
export const API_REGISTER = "/signup";
export const API_FORGOT_PASSWORD = "/forgotpassword";
export const API_VERIFY_CODE = "/verifycode";
export const API_RESET_PASSWORD = "/resetpassword";
export const API_SAVE_ERROR_LOG = "/saveErrorLog";
// export const ROUTE_SOCIAL_LOGIN_CALLBACK = "/socialLoginCallback/:type";

export const API_DASHBOARD = "/dashboard";
export const API_ACCOUNT_SETTINGS = "/accountsetting";
export const API_UPDATE_EMAIL = "/changeemailrequest";
export const API_VERIFY_NEW_EMAIL = "/changeemail";
export const API_CHANGE_PASSWORD = "/changepassword";
export const API_PUBLIC_PROFILE = "/publicprofile";
export const API_LOAD_2FA = "/enable2fa";
export const API_ENABLE_2FA = "/verify2fa";
export const API_DISABLE_2FA = "/disable2fa";
export const API_WASENDOTP = "/wasendotp";
export const API_VERIFYWASENDOTP = "/verifywasendotp";
export const API_VERIFYWHATSAPP = "/verifywhatsapp";
// export const API_TOGGLE_2FA = "/toggle-2fa";
export const API_GET_PROFILE = "/getprofile";
export const API_SIGN_MOU = "/mou";
export const API_VERIFY_ACCOUNT_EMAIL = "/emailverification";
export const API_UPLOAD_PHOTO = "/uploadphoto";
export const API_GET_BANNER_IMAGE = "/getbannerimage";
export const API_LOGOUT = "/logout";


export const API_EARNINGS_SUMMARY = "/earningsSummary";
export const API_GET_STOCK_PHOTOS_EARNINGS = "/getStockPhotosEarnings";
export const API_GET_MARBLE_PHOTOS_EARNINGS = "/getMarblePhotosEarnings";
export const API_GET_PREMIUM_PHOTOS_EARNINGS = "/getPremiumPhotosEarnings";
export const API_GET_ADJUSTMENTS = "/getAdjustments";
export const API_GET_PAYMENT_HISTORY = "/getPaymentHistory";
export const API_GET_NOTIFICATION_SETTINGS = "/getNotificationSettings";
export const API_UPDATE_NOTIFICATION_SETTINGS = "/updateNotificationSettings";
export const API_GET_FAQ_HELP = "/getFaqHelp";
export const API_GET_FAQ_HELP_TOPIC = "/getFaqHelpTopic";
export const API_GET_REFERRALS = "/getReferrals";
export const API_SEND_FEEDBACK = "/sendFeedback";
export const API_GET_CONTACT_HELP = "/getContactHelp";
export const API_GET_CONTACT_HELP_TOPIC = "/getContactHelpTopic";
export const API_CONTACT_US = "/contactUs";
export const API_GET_INVOICES_MONTH_WISE = "/getInvoicesMonthWise";
export const API_GET_WITHDRAW_METHOD = "/getWithdrawMethod";
export const API_UPDATE_PAYOUT = "/updatePayout";
export const API_UPDATE_TAX_CENTER = "/updatetaxcenter";
export const API_GET_DAILY_EARNING_DOWNLOAD = "/getDailyEarningDownload";
export const API_GET_TOTAL_EARNING_DATEWISE = "/getTotalEarningsDateWise"
export const API_GET_STOCK_TOTAL_EARNING_DATEWISE = "/getStockPhotosEarningsDateWise"
export const API_GET_MARBLE_TOTAL_EARNING_DATEWISE = "/getMarblePhotosEarningsDateWise"
export const API_GET_PREMIUM_TOTAL_EARNING_DATEWISE = "/getPremiumPhotosEarningsDateWise"
export const API_GET_DOWNLOAD_INVOICE = "/getDownloadInvoice";
export const API_GET_INVOICES_DAY_WISE = "/getInvoicesDayWise";
export const API_GET_DOWNLOAD_INVOICE_ZIP = "/getDownloadInvoiceZip";
export const API_GET_DOWNLOAD_INVOICE_DAYWISE = "/getDownloadInvoiceDaywise";
export const API_GET_COUNTRIES = "/getCountries";
export const API_GET_SOCIAL_LOGIN_URL = "/getSocialLoginUrl";
export const API_GET_PAYOUT = "/getPayout";
export const API_GET_TAX_CENTER = "/getTaxcenter";
export const API_SEARCH_FAQ_HELP_TOPIC = "/searchFaqHelpTopic";

export const API_GET_SOCIAL_AUTO_POST_LOGIN_URL = "/getSocialAutoPostLoginUrl";
export const API_GET_SOCIAL_MEDIA_USER_STATUS = "/getSocialMediaUserStatus";
export const API_CHANGE_USER_SOCIAL_MEDIA_STATUS = "/changeUserSocialMediaStatus";
export const API_GET_LANGUAGE = "/getLanguage";
export const API_GET_EARNINGS_AMOUNT = "/getEarningsAmount";
export const API_MONTHLY_EARNING_CHART = "/monthlyEarningChart";
export const API_PERFORMERS_FILTER = "/performersFilter";
export const API_TOP_PERFORMERS = "/topPerformers";
export const API_GET_LATEST_DOWNLOAD_MAP = "/getLatestDownloadMap";
export const API_PORTFOLIO_IMAGES = "/portfolioImages";
export const API_SAVE_PORTFOLIO_IMAGES = '/savePortfolioImages'
export const API_DELETE_PORTFOLIO_IMAGES = '/deletePortfolioImages'
export const API_PORTFOLIO_COLLECTION = "/portfolioCollection";
export const API_GET_COLLECTION_IMAGES = "/getCollectionImages";
export const API_REMOVE_IMAGE_FROM_COLLECTION = "/removeImageFromCollection";
export const API_COLLECTION_INSERT = "/collectionsInsert";
export const API_COLLECTION_DELETE = "/collectionDelete";
export const API_ADD_COLLECTION = "/addCollection";
export const API_UPLOAD_FILE = "/uploadFile";
export const API_GET_PROCESS_CONTENT = "/getProcessContent";
export const API_GET_UPLOAD_PENDING = "/getUploadPending";
export const API_REMOVE_UPLOAD_PENDING = "/removeUploadPending";
export const API_GET_REVIEW_IMAGES = "/getReviewedImages";
export const API_GET_CUST_REF_PHOTO = "/getCustRefPhoto";
export const API_GET_CUST_REF_PHOTO_TEXT = "/getCustRefPhotoText";
export const API_FOOTER = "/footer";
export const API_WA_SEND_OTP = "/sendWhatsappOTP";
export const API_WA_VERIFY_OTP = "/verifyWhatsappOTP";
export const API_GET_PROCESS_FILE = "/getProcessFile";
export const API_PROCESS_FILE_REMOVE = "/processFileRemove";
export const API_UPLOAD_PENDING = "/getUploadPending";
export const API_UPLOAD_SUBMIT = "/uploadSubmit";
export const API_GET_CATEGORIES = "/getCategories";
export const API_GET_IMAGE_PROCESS_SUBMIT_CONTENT = "/getImageProcessSubmitContent";
export const API_GET_SUBMIT_CONTENT_KEYWORD_SUGGESTION = "/getSubmitContentKeywordSuggestion";
export const API_GET_SUBMIT_CONTENT_TITLE_SUGGESTION = "/getSubmitContentTitleSuggestion";
export const API_GET_SUBMIT_CONTENT_DESCRIPTION_SUGGESTION = "/getSubmitContentDescSuggestion";
export const API_GET_UPLOAD_SUBMIT = "/autoUploadSubmit";
export const API_GET_KEYWORD_IMAGES = "/getKeywordImages";
export const API_GET_IMAGES_KEYWORD = "/getImagesKeyword";
export const API_GET_SUBMITED_REVIEWED_IMAGES = "/getSubmitedReviewedImages";
export const API_SAVE_SUBMIT_CONTENT_DATA = "/saveSubmitContentData";
export const API_GET_SUBMIT_CONTENT_DATA = "/getSavedSubmitContentData";
export const API_SAVE_AUTO_SUBMIT = "/autoSubmitFlag";
export const API_GET_SUBMIT_CONTENT_CATEGORY = "/getSubmitContentCategory";
export const API_GET_IP_INFO = "/getIpInfo";
export const API_VERIFY_WA_LINK = "/verifywalink";
export const API_UNVERIFY_WA = "/unverifyWhatsapp";
export const API_IS_WA_VERIFIED = "/isWhatsappVerified";
export const API_IS_EMAIL_VERIFIED = "/isEmailVerified";
export const API_VERIFY_EMAIL_LINK = "/verifyEmaillink";
export const API_PASSWORD_RESET_LINK = "/pwdResetLink";
export const API_SOCIAL_LOGIN_PASSWORD_CREATE = "/socialLoginCreatePassword";
export const API_GET_EARNINGS_DATA = "/getEarningsData";
export const API_REGISTER_UPLOAD_PHOTO = "/registeruploadphoto";
export const API_USERLOGIN = "userLogin";
export const API_GET_UPLOAD_FOLDER_NAME ="getUploadFolderName";
export const API_USER_FTP_FILE_GENERATE ="userFTPFileGenerate";